import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Marien Wolthuis - Engineer | Interaction Designer | Maker', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Curious what I do? Come have a look!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '', // Hello, I am
  name: 'Marien',
  subtitle: "I'm an engineer, interaction designer, and maker.",
  cta: '', // Text for the 'know more' button
};

// ABOUT DATA
export const aboutData = {
  img: 'aboutShot-3.png',
  paragraphOne:
    'I love making things. As a kid I made wooden robots, as a student I built interactive robots, and now I design meaningful interactions with physical products by making functioning prototypes.',
  paragraphTwo:
    "My largest talent is the ability to learn new skills and technologies. For instance, I've familiarised myself in three weeks with the Microsoft Azure Sphere hardware and software ecosystem in order to give a workshop on it.",
  paragraphThree:
    "Outside of work, I like to go windsurfing or snowboarding when I have the chance, or to create little things around the house using my 3D printer (and occasionally design upgrades for the printer). An example of this is a new tuning peg I've created for my bass guitar, as the original parts are no longer available - now it's even more special to me!",
  resume: 'https://www.linkedin.com/in/marienwolthuis/#experience-section', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'wireless-arcade-button.jpg',
    title: 'Wireless arcade button',
    info:
      'Imagine being able to play pinball on any regular table. What would the controls look like? How can you make it super portable?', // First paragraph
    info2:
      'My previous employer gave me a month to answer this question with a prototype that can be used during the development and play of tabletop arcade games.', // Second paragraph
    url: 'wireless-arcade-button',
    repo: '', // if no repo, the button will not show up
    cta: "Let's play", // Button text, defaults to "See Live"
  },
  {
    id: uuidv1(),
    img: 'giorgio-infront-laptop.jpg',
    title: 'Giorgio, the robotic musician',
    info:
      "Sometimes it's hard to find that one missing member of your band. What if you could buy one? How can humans and robots work together?",
    info2:
      'For my graduation project as an Interaction Designer, I was asked to design a robot fit for live performances, taking both the interactions with the musicians and audience perception into account.',
    url: 'giorgio',
    repo: '', // if no repo, the button will not show up
    cta: 'Meet Giorgio',
  },
  {
    id: uuidv1(),
    img: 'skills_bucket-03.png',
    title: 'My collection of skills',
    info:
      "Over the years I've acquired a lot of useful skills that don't necessarily show in the listed projects. This is a collection of the most relevant ones, and some examples to show how I have applied them.",
    info2: '',
    url: 'skills',
    repo: '',
    cta: 'Learn more',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '', // Contact phrase
  btn: '', // Button text
  email: 'jobs@marienwolthuis.nl', // Email address
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: uuidv1(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: uuidv1(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/marienwolthuis',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/dotCID',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
